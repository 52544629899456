import React from 'react'
import { Link } from 'react-router-dom'

import './Room.scss'

const Room = ({ room }) => {
  return (
    <div className='room'>
      <div className='container-1'>
        <div>
          <h1 className='subtitle is-5 has-text-white'>{room}</h1>
        </div>
        <div>
          <Link
            to={{ pathname: '/join', room: room }}
            className='has-text-white'
          >
            Join
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Room
