import React from 'react'

const Input = ({ message, setMessage, sendMessage }) => {
  return (
    <div style={{ width: '95%', margin: '5px 0' }}>
      <input
        type='text'
        className='input'
        placeholder='Enter Message'
        value={message}
        onChange={e => setMessage(e.target.value)}
        onKeyPress={e => (e.key === 'Enter' ? sendMessage(e) : null)}
      />
    </div>
  )
}

export default Input
