import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Room from '../Room'
import './Home.scss'

const Home = () => {
  const [roomCount, setRoomCount] = useState(0)
  const [rooms, setRooms] = useState([])

  useEffect(() => {
    async function getRooms () {
      const res = await fetch('http://localhost:5000/allrooms')
      const { roomCount, rooms } = await res.json()

      setRoomCount(roomCount)
      setRooms(rooms)
    }

    getRooms()
  }, [])

  return (
    <div className='home container'>
      <h1 className='title is-2 has-text-white'>An anonymous chat room</h1>
      <h1 className='title is-3 has-text-white'>
        Number of Rooms active: {roomCount}
      </h1>
      <div className='room-container'>
        {rooms.map((room, i) => (
          <Room key={i} room={room} />
        ))}
      </div>
      <Link to='join' className='has-text-white'>
        <button type='submit' className='button is-primary is-medium'>
          Create Room and Chat
        </button>
      </Link>
    </div>
  )
}

export default Home
