import React from 'react'

import './Message.scss'

const Message = ({ id, user, text, name }) => {
  return (
    <div
      className={`message ${user === name.toLowerCase() ? 'right' : 'left'}`}
    >
      <p>{text}</p>
      <p className='has-text-grey'>{user}</p>
    </div>
  )
}

export default Message
