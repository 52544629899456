import React from 'react'
import ScrollToBottom from 'react-scroll-to-bottom'

import Message from '../Message'
import './Messages.scss'

const Messages = ({ messages, name }) => {
  return (
    <ScrollToBottom className='messages'>
      {messages.map((message, id) => (
        <Message
          key={id}
          id={id}
          user={message.user}
          text={message.text}
          name={name}
        />
      ))}
    </ScrollToBottom>
  )
}

export default Messages
