import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './App.scss'

import Home from './components/Home'
import Chat from './components/Chat'
import Join from './components/Join'

const App = () => {
  return (
    <div className='app'>
      <Router>
        <Route path='/' component={Home} exact />
        <Route path='/chat' component={Chat} />
        <Route path='/join' component={Join} />
      </Router>
    </div>
  )
}

export default App
