import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import './Join.scss'

const Join = ({ location }) => {
  const [name, setName] = useState('')
  const [room, setRoom] = useState('')

  useEffect(() => {
    const { room } = location

    if (room) setRoom(room)
  }, [location])

  return (
    <div className='join'>
      <h1 className='title has-text-white is-2 has-text-centered'>Join Chat</h1>
      <div className='field'>
        <label className='label has-text-white is-2'>Name</label>
        <div className='control'>
          <input
            type='text'
            placeholder='Enter Name'
            className='input is-primary'
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
        </div>
      </div>
      <div className='field'>
        <label className='label has-text-white is-2'>Room</label>
        <div className='control'>
          <input
            type='text'
            placeholder='Enter Room'
            value={room}
            className='input is-primary center'
            onChange={e => setRoom(e.target.value)}
            required
          />
        </div>
      </div>
      <div className='button-container has-text-centered'>
        <Link
          onClick={e => (!name || !room ? e.preventDefault() : null)}
          to={`/chat?name=${name}&room=${room}`}
        >
          <button type='submit' className='button is-primary is-medium'>
            Join Room
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Join
