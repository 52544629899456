import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'

import Input from '../Input'
import Messages from '../Messages'
import RoomInfo from '../RoomInfo'
import './Chat.scss'

let socket

const Chat = ({ location, history }) => {
  const [name, setName] = useState('')
  const [room, setRoom] = useState('')
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const SERVER = 'https://anonymous-chat-room.herokuapp.com/'

  useEffect(() => {
    // * parse  name of the user and chat room
    const { room, name } = queryString.parse(location.search)
    setName(name)
    setRoom(room)

    socket = io(SERVER)

    // * user joins
    socket.emit('join', { name, room }, error => {
      if (error) {
        history.push('/join')
      }
    })

    // * works like componentDidUnmount()
    return () => {
      socket.emit('disconnect')

      socket.off()
    }
  }, [SERVER, location.search])

  useEffect(() => {
    socket.on('message', message => {
      setMessages([...messages, message])
    })

    return () => {
      socket.emit('disconnect')
      socket.off()
    }
  }, [messages])

  const sendMessage = e => {
    e.preventDefault()

    if (message) {
      socket.emit('sendMessage', message, () => setMessage(''))
    }
  }

  return (
    <div className='chat'>
      {
        <Helmet>
          <title>Room - {room}</title>
        </Helmet>
      }
      <RoomInfo room={room} />
      <Messages messages={messages} name={name} />
      <Input
        message={message}
        sendMessage={sendMessage}
        setMessage={setMessage}
      />
    </div>
  )
}

export default Chat
