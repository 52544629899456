import React from 'react'

import './RoomInfo.scss'

const RoomInfo = ({ room }) => {
  return (
    <div className='roominfo'>
      <div className='leftInnerContainer'>
        <h3>{room}</h3>
      </div>
      <div className='rightInnerContainer'>
        <a href='/'>Close Chat</a>
      </div>
    </div>
  )
}

export default RoomInfo
